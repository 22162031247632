import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import Divider from "../../components/divider"
import GebaerdenVideoGrid from "../../content/shared/gebaerden-video-grid"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import VideoPlayer from "../../components/video-player"

const GebaerdenSpracheMediaguide = () => {
  const data = useStaticQuery(graphql`
    {
      poster: file(
        relativePath: { eq: "videos/poster-gebaerdensprache-mediaguide.jpg" }
      ) {
        ...largeImage
      }
      ogImage: file(relativePath: { eq: "og_images/leichte-sprache.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="aussen">
      <Seo
        title="Media-Guide mit Inhalten in Gebärdensprache"
        description="In diesen Videos in Gebärdensprache erfahren Sie mehr über den Dichter Friedrich Hölderlin, das Museum Hölderlinturm und unsere Angebote in Gebärdensprache."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Gebärdensprache",
              link: "/gebaerdensprache",
            },
            {
              title: "Media-Guide",
              link: "/gebaerdensprache/mediaguide",
            },
          ]}
        />
        <PageTitle>Media-Guide mit Inhalten in Gebärdensprache</PageTitle>

        <VideoPlayer src="gebaerdensprache/mediaguide" poster={data.poster} />
        <Divider size={4} />
        <GebaerdenVideoGrid active="mediaguide" />
      </Stack>
    </Layout>
  )
}

export default GebaerdenSpracheMediaguide
